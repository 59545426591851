import * as React from 'react'
import classNames from 'classnames'

import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Theme, makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Divider from '@material-ui/core/Divider'
import ArrayForward from '@material-ui/icons/ArrowForward'

import AnchorLink from 'react-anchor-link-smooth-scroll'

import BankCard from '../components/BankCard'
import SearchBankInput from '../components/SearchBankInput'
import { hokkaidoTohoku, kanto, koshinetsuHokuriku, tokai, kansai, chugoku, kyushu } from '../const/shinkin'

const useStyles = makeStyles((theme: Theme) =>
  ({
    logoImgGrid: {
      textAlign: "center",
    },
    logoImg: {
      width: "auto",
      height: "200px",
      marginTop: "-50px",
      marginBottom: "-30px",
      [theme.breakpoints.down('sm')]: {
        height: "100px",
        marginTop: 0,
        marginBottom: "-20px",
      }
    },
    title: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        fontWeight: 'bold',
      }
    },
    heroUnit: {
      backgroundImage: `url(${"/img/top.jpg"})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundColor: theme.palette.background.paper,
      color: '#ffffff',
    },
    heroContent: {
      padding: `${theme.spacing(5)}px`,
      [theme.breakpoints.down('sm')]: {
        padding: `${theme.spacing(2)}px`,
      }
    },
    toBatonzLinkGrid: {
      textAlign: "right",
      [theme.breakpoints.down('sm')]: {
         textAlign: "center",
         marginTop: "1rem",
         marginBottom: "1rem",
      }
    },
    toBatonzLink: {
      color: '#ffffff',
    },
    arrowForward: {
      color: '#ffffff',
      fontSize: 10
    },
    descriptionGrid: {
      textAlign: 'right',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      }
    },
    descriptionTitle: {
      color: '#ffffff',
      [theme.breakpoints.up('lg')]: {
        paddingRight: '5vw',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: "12px"
      }
    },
    tab: {
      fontSize: '1rem',
      fontWeight: 'bold'
    },
    layout: {
      width: '1100px',
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.down('md')]: {
        width: '900px',
      },
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
      },
    },
    cardGrid: {
      padding: `${theme.spacing(3)}px 0`,
    },
    areaName: {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center'
      }
    },
    banksTabContainer: {
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center'
      }
    },
    divider: {
      margin: theme.spacing(5),
    },
    footer: {
      backgroundColor: '#292929',
      color: '#ffffff',
      paddingTop: theme.spacing(2),
      paddingBottom: '0.5rem',
    },
    footerLink: {
      textDecoration: 'none',
      color: '#ffffff',
      [theme.breakpoints.down('sm')]: {
        fontSize: '15px'
      }
    }
  })
)

interface TabContainerProps {
  areaName: string,
  banks: Array<any>
}

function TabContainer(props: TabContainerProps) {
  const classes = useStyles()
  return (
    <React.Fragment>
      <Typography
        id={props.areaName}
        variant="h4"
        component="h2"
        gutterBottom
        className={classes.areaName}>
        {props.areaName}
      </Typography>
      <Grid
        container
        spacing={4}
        className={classes.banksTabContainer}>
        {props.banks.map(bank => (
          <Grid item key={bank.name} sm={6} md={4} lg={3}>
            <BankCard
              name={bank.name}
              id={bank.id} />
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  )
}

export default function Index() {
  const classes = useStyles()
  const [tabValue, setTabValue] = React.useState('北海道・東北')

  function handleTabChange(event: React.ChangeEvent<{}>, newValue: string) {
    setTabValue(newValue)
  }

  return (
    <React.Fragment>
      <main>
        {/* Hero unit */}
        <div className={classes.heroUnit}>
          <div className={classes.heroContent}>
            <Grid
              container
              spacing={0}>
              <Grid
                item
                md={6}
                xs={12}
                className={classes.logoImgGrid}>
                <img
                  className={classes.logoImg}
                  src="../../img/batonz-logo.png" />
                <Typography
                  component="h1"
                  variant="h4"
                  align="center"
                  color="inherit"
                  gutterBottom
                  className={classes.title}>
                  信用金庫のお客様向け専用サイト
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
                className={classes.toBatonzLinkGrid}>
                <a
                  href="https://batonz.jp/login"
                  target="_blank"
                  className={classes.toBatonzLink}>
                  会員登録がお済の方はこちらから
                  <ArrayForward className={classes.arrowForward} />
                </a>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={4}>
              <Grid
                item
                md={6}
                xs={12}>
                <SearchBankInput />
              </Grid>
              <Grid
                className={classes.descriptionGrid}
                item
                md={6}
                xs={12}>
                <Typography
                  className={classes.descriptionTitle}
                  variant="h5"
                  component="div"
                  >
                  ご紹介元の信用金庫のロゴを押下し
                </Typography>
                <Typography
                  className={classes.descriptionTitle}
                  variant="h5"
                  component="div"
                  >
                  会員登録をしてください
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
        <AppBar position="static">
          <Tabs
            value={tabValue}
            indicatorColor="secondary"
            textColor="secondary"
            scrollButtons="on"
            variant="scrollable"
            onChange={handleTabChange}>
            <Tab
              className={classes.tab}
              value="北海道・東北"
              label="北海道・東北"
              component={AnchorLink}
              href="#北海道・東北"
              offset="20" />
            <Tab
              className={classes.tab}
              value="関東"
              label="関東"
              component={AnchorLink}
              href="#関東"
              offset="20" />
            <Tab
              className={classes.tab}
              value="甲信越・北陸"
              label="甲信越・北陸"
              component={AnchorLink}
              href="#甲信越・北陸"
              offset="20" />
            <Tab
              className={classes.tab}
              value="東海"
              label="東海"
              component={AnchorLink}
              href="#東海"
              offset="20" />
            <Tab
              className={classes.tab}
              value="関西"
              label="関西"
              component={AnchorLink}
              href="#関西"
              offset="20" />
            <Tab
              className={classes.tab}
              value="中国"
              label="中国"
              component={AnchorLink}
              href="#中国"
              offset="20" />
            <Tab
              className={classes.tab}
              value="九州・沖縄"
              label="九州・沖縄"
              component={AnchorLink}
              href="#九州・沖縄"
              offset="20" />
          </Tabs>
        </AppBar>
        <div className={classNames(classes.layout, classes.cardGrid)}>
          <TabContainer areaName="北海道・東北" banks={hokkaidoTohoku} />
          <Divider className={classes.divider} variant="middle" />
          <TabContainer areaName="関東" banks={kanto} />
          <Divider className={classes.divider} variant="middle" />
          <TabContainer areaName="甲信越・北陸" banks={koshinetsuHokuriku} />
          <Divider className={classes.divider} variant="middle" />
          <TabContainer areaName="東海" banks={tokai} />
          <Divider className={classes.divider} variant="middle" />
          <TabContainer areaName="関西" banks={kansai} />
          <Divider className={classes.divider} variant="middle" />
          <TabContainer areaName="中国" banks={chugoku} />
          <Divider className={classes.divider} variant="middle" />
          <TabContainer areaName="九州・沖縄" banks={kyushu} />
        </div>
      </main>
      {/* Footer */}
      <footer className={classes.footer}>
        <Typography
          variant="h6"
          align="center"
          gutterBottom>
          <Grid
            container
            spacing={3}
            justify="center">
            <Grid
              item>
              <a
                href="https://batonz.jp/lp/about/"
                target="_blank"
                className={classes.footerLink}>
                BATONZとは
              </a>
            </Grid>
            <Grid
              item>
              <a
                href="https://batonz.co.jp"
                target="_blank"
                className={classes.footerLink}>
                運営会社
              </a>
            </Grid>
            <Grid
              item>
              <a
                href="https://support.batonz.jp/hc/ja/requests/new"
                target="_blank"
                className={classes.footerLink}>
                お問い合わせ
              </a>
            </Grid>
          </Grid>
        </Typography>
        <Typography
          variant="subtitle2"
          align="center"
          component="p">
          © Copyright 2019 株式会社バトンズ
        </Typography>
      </footer>
      {/* End footer */}
    </React.Fragment>
  )
}
