// Base import
import * as React from 'react'
import { Theme, makeStyles } from '@material-ui/core/styles'

// custom import
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import Grow from '@material-ui/core/Grow'

import InViewMonitor from 'react-inview-monitor'

const useStyles = makeStyles((theme: Theme) =>
  ({
    card: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '3rem',
      paddingRight: '3rem',
      height: '166px',
      [theme.breakpoints.down(('sm'))]: {
        height: '210px',
      },
      [theme.breakpoints.down(('xs'))]: {
        height: 'auto',
        width: '270px',
        paddingLeft: '2rem',
        paddingRight: '2rem',
      }
    },
    cardMedia: {
      height: 0,
      backgroundSize: 'contain',
      paddingTop: '56.25%', // 16:9
    },
    cardContent: {
      flexGrow: 1,
      textAlign: 'center',
      marginLeft: '-3rem',
      marginRight: '-3rem',
      paddingLeft: 0,
      paddingRight: 0,
    },
    bankName: {
      textDecoration: 'none',
      [theme.breakpoints.down(('sm'))]: {
        fontSize: '30px',
      }
    },
    a: {
      textDecoration: 'none',
    }
  })
)

type BankCardProps = {
  name: string,
  id: number,
}

export default function BankCard(props: BankCardProps) {
  const classes = useStyles()
  const [visible, setVisible] = React.useState<boolean>(false)

  function handleView() {
    setVisible(true)
  }

  return (
    <React.Fragment>
      <InViewMonitor
        onInView={handleView}
        intoViewMargin="-50px">
        <a
          id={`${props.id}`}
          href={`https://batonz.jp/user_registrations/?utm_source=shinkin_batonz&utm_medium=shinkin_batonz&utm_campaign=shinkin_batonz&inviter=${props.id}`}
          target="_blank"
          className={classes.a}>
          <Grow
            in={visible}>
            <Card
              className={classes.card}>
              <CardMedia
                className={classes.cardMedia}
                image={`/img/shinkin/${props.name}.png`}
                title={props.name}
              />
              <CardContent
                className={classes.cardContent}>
                <Typography
                  gutterBottom
                  component="h3"
                  variant="h5"
                  style={props.name.length > 8 ? { fontSize: '1.5rem' } : null}
                  className={classes.bankName}>
                  {props.name}
                </Typography>
              </CardContent>
            </Card>
          </Grow>
        </a>
      </InViewMonitor>
    </React.Fragment>
  )
}
