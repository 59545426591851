export const hokkaidoTohoku = [
  {
    name: '一関信用金庫',
    id: 895,
  }
]

export const kanto = [
  {
    name: 'みと地域総合研究所',
    id: 782
  },
  {
    name: '桐生信用金庫',
    id: 1147,
  },
  {
    name: '高崎信用金庫',
    id: 911,
  },
  {
    name: '川口信用金庫',
    id: 6504,
  },
  {
    name: '飯能信用金庫',
    id: 1380,
  },
  {
    name: '城北信用金庫',
    id: 1381,
  },
  {
    name: '多摩信用金庫',
    id: 2153
  },
  {
    name: '中栄信用金庫',
    id: 1362,
  },
  {
    name: '平塚信用金庫',
    id: 797,
  },
  {
    name: '横浜信用金庫',
    id: 1420,
  }
]

export const koshinetsuHokuriku = [
  {
    name: '三条信用金庫',
    id: 903,
  },
  {
    name: '長岡信用金庫',
    id: 899,
  },
  {
    name: '新潟信用金庫',
    id: 910,
  },
  {
    name: 'のと共栄信用金庫',
    id: 1538,
  },
  {
    name: '福井信用金庫',
    id: 905,
  },
  {
    name: '甲府信用金庫',
    id: 1371,
  },
  {
    name: 'アルプス中央信用金庫',
    id: 939,
  },
  {
    name: '飯田信用金庫',
    id: 1271,
  },
  {
    name: '上田信用金庫',
    id: 1303,
  },
  {
    name: '諏訪信用金庫',
    id: 1294,
  },
  {
    name: '長野信用金庫',
    id: 1096,
  },
  {
    name: '松本信用金庫',
    id: 1032,
  }
]

export const tokai = [
  {
    name: '岐阜信用金庫',
    id: 924,
  },
  {
    name: '東濃信用金庫',
    id: 1189
  },
  {
    name: 'しずおか焼津信用金庫',
    id: 1011,
  },
  {
    name: '静清信用金庫',
    id: 1346,
  },
  {
    name: '浜松いわた信用金庫',
    id: 845,
  },
  {
    name: '富士信用金庫',
    id: 2323,
  },
  {
    name: '沼津信用金庫',
    id: 1691,
  },
  {
    name: '三島信用金庫',
    id: 1454,
  },
  {
    name: '愛知信用金庫',
    id: 6248,
  },
  {
    name: '豊田信用金庫',
    id: 1049,
  }
]

export const kansai = [
  {
    name: '滋賀中央信用金庫',
    id: 789,
  },
  {
    name: '京都中央信用金庫',
    id: 1087,
  },
  {
    name: '京都信用金庫',
    id: 1091,
  },
  {
    name: '大阪商工信用金庫',
    id: 3983,
  },
  {
    name: '尼崎信用金庫',
    id: 1119,
  },
  {
    name: '神戸信用金庫',
    id: 1379,
  },
  {
    name: '兵庫信用金庫',
    id: 1160,
  },
  {
    name: '奈良中央信用金庫',
    id: 1317,
  }
]

export const chugoku = [
  {
    name: 'しまね信用金庫',
    id: 2999,
  },
  {
    name: '玉島信用金庫',
    id: 1882,
  },
  {
    name: '呉信用金庫',
    id: 1980,
  }
]

export const kyushu = [
  {
    name: '福岡ひびき信用金庫',
    id: 922,
  },
  {
    name: '大分みらい信用金庫',
    id: 2812,
  },
  {
    name: 'コザ信用金庫',
    id: 1442,
  }
]
